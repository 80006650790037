import moment from 'moment'

export const overviewConfig = [
	{ label: 'Agent', key: 'AgentName', sortable: true },
	{ label: 'APV', key: 'APV', type: 'currency', sortable: true, sortBy: true, sortDesc: true },
	{ label: 'Apps', key: 'Apps' },
	{ label: 'PCS Apps', key: 'PCSApps' },
	{ label: 'Resets', key: 'FIFResets' },
	{ label: 'UWs', key: 'UWs' },
	{ label: 'NWs', key: 'NWs' },
	{ label: 'RTWs', key: 'RTWs' },
	{ label: 'CWs', key: 'CWs' },
	{ label: 'Mail-In', key: 'MailInLeads' },
	{ label: 'Call-In', key: 'CallInLeads' },
	{ label: 'Dig. Lighthouse', key: 'LighthouseLeads' },
	{ label: 'Dig. Economy', key: 'EconomyLeads' },
	{ label: 'Bonus', key: 'AnalogBLeads' },
]

export const downlinesConfig = [
	{ label: 'Agent', key: 'AgentName', sortable: true },
	{ label: 'Contract Level', key: 'ContractLevel', type: 'default' },
	{ label: 'Leadership Level', key: 'LeadershipLevel', type: 'default' },
	{ label: 'APV', key: 'APV', type: 'currency', sortable: true, sortBy: true, sortDesc: true },
	{ label: 'Apps', key: 'Apps' },
	{ label: 'PCS Apps', key: 'PCSApps' },
	{ label: 'Resets', key: 'FIFResets' },
	{ label: 'Mail-In', key: 'MailInLeads' },
	{ label: 'Call-In', key: 'CallInLeads' },
	{ label: 'Dig. Lighthouse', key: 'LighthouseLeads' },
	{ label: 'Dig. Economy', key: 'EconomyLeads' },
	{ label: 'Bonus', key: 'AnalogBLeads' },
]

export const past12MonthsConfig = [
	{ label: 'Agent', key: 'AgentName', sortable: true },
]

export const getLast12Months = () => {
	let date = moment().subtract(1, 'months')
	return Array(12).fill(1).map(() => {
		const current = date.format('YYYY-MM')
		date = date.subtract(1, 'months')
		return current
	}).reverse()
}

export const getEmptyData = (id = 'id') => Array(7).fill(0).map((_, idx) => ({ [id]: idx }))

export const getDefaultOptions = (config) => {
	const opt = { page: 1, itemsPerPage: 7 }
	const item = config.find(({ sortBy }) => sortBy)
	if (!item) {
		return {
			...opt,
			sortBy: [],
			sortDesc: [],
		}
	}
	return  {
		...opt,
		sortBy: [item.key],
		sortDesc: typeof item.sortDesc === 'boolean' ? [item.sortDesc] : [],
	}

}
