<template>
	<DashCard
		title="Recruiting and Onboarding"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: Recruiting and Onboarding"
		style="min-height:100%;"
		@reload="$emit('reload', 'accelerate_recruiting_onboarding')"
	>
		<template #icon><Icon /></template>

		<div class="card-content" :class="computedClass">
			<v-data-table
				:headers="headers"
				:items="computedData"
				item-key="key"
				mobile-breakpoint="0"
				class="card-table"
				dark hide-default-footer hide-default-header disable-sort
			>
				<template #item.metric="{ item }">
					<span class="nowrap"><strong>{{ item.metric }}</strong></span>
				</template>
				<template #item.value="{ item }">
					<span class="nowrap">{{ formatValue(item.value) }}</span>
				</template>
			</v-data-table>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/ActivityReportIcon.vue'

const config = [
	{ key: 'interviews', metric: 'Interviews Conducted' },
	{ key: 'invites_sent', metric: 'Invites Sent' },
	{ key: 'invites_accepted', metric: 'Invites Accepted' },
	{ key: 'rtws', metric: 'RTW\'s' },
]

export default {
    components: {
		DashCard,
		Icon,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		headers () {
			return [{
                text: 'Metric',
                value: 'metric',
            }, {
                text: 'Value',
                value: 'value',
			}]
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				const { data } = this.data
				const monthly = config.map(item => ({ ...item, value: data[`${item.key}_monthly`] }))
				const weekly = config.map(item => ({ ...item, value: data[`${item.key}_weekly`] }))
				return this.monthlyPeriod ? monthly : weekly
			}
			return config.map(item => ({ ...item, value: '' }))
		},
		computedClass () {
			return [
				this.isMobileView && 'card-content--mobile',
			]
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatValue (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;

	--td-padding: 0 1rem;

	&--mobile {
		--td-padding: 0 0.75rem;
	}

	.card-table {
		$border-bottom: 1px solid $color-border !important;

		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep tbody tr td {
			height: 36px;
			border-bottom: $border-bottom;
			padding: var(--td-padding);

			&:first-child {
				width: 8rem;
				background-color: $color-water-green-20;
			}
		}
	}
}
</style>
