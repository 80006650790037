<template>
	<DashCard
		title="Quility Lead Marketplace"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		@reload="$emit('reload', 'activate_qlm')"
	>
		<template #icon><LeadMarketplaceIcon /></template>

		<div class="card-content">
			<v-data-table
				:headers="headers"
				:items="computedData"
				item-key="index"
				mobile-breakpoint="0"
				class="card-table"
				dark hide-default-footer disable-sort disable-pagination disable-filtering
			>
				<template #item="{ item }">
					<tr v-if="item.LeadSource === item.LeadSubSource">
						<td colspan="5" class="source-title">{{ item.LeadSource }}</td>
					</tr>

					<tr v-else-if="item.LeadSubSource === 'Total'" class="summary-row">
						<td></td>
						<td>{{ formatItem(item.LeadPurchase) }}</td>
						<td>
							<span class="nowrap">
								<span v-if="item.LeadCost < 0">-</span>
								<span class="with-currency">{{ formatMoney(item.LeadCost) }}</span>
							</span>
						</td>
						<td>{{ formatPercent(item.CloseRatio) }}%</td>
						<td>{{ formatPercent(item.TPCloseRatio) }}%</td>
					</tr>

					<tr v-else>
						<td class="sub-source-title">{{ item.LeadSubSource }}</td>
						<td>{{ formatItem(item.LeadPurchase) }}</td>
						<td>
							<span class="nowrap">
								<span v-if="item.LeadCost < 0">-</span>
								<span class="with-currency">{{ formatMoney(item.LeadCost) }}</span>
							</span>
						</td>
						<td>{{ formatPercent(item.CloseRatio) }}%</td>
						<td>{{ formatPercent(item.TPCloseRatio) }}%</td>
					</tr>
				</template>
			</v-data-table>
		</div>
	</DashCard>
</template>

<script>
import { groupBy } from 'lodash'

import DashCard from '../DashCard.vue'
import LeadMarketplaceIcon from '../../Icons/LeadMarketplaceIcon.vue'
import CalculationDot from '../../Components/CalculationDot.vue'

const checkNullish = (value) => {
	return [undefined, null, ''].includes(value)
}

const calculateSummary = (data, key) => {
	const hasNullish = data.some(item => checkNullish(item[key]))
	if (hasNullish) { return null }
	const summary = data?.reduce?.((acc, item) => {
		acc += Number(item[key]) || 0
		return acc
	}, 0)
	return Math.round(summary * 1000) / 1000
}

const getQLM = (period, data) => {
	const QLM = data
		.filter(({ LeadSubSource }) => LeadSubSource)
		.map((item, idx) => ({
			index: idx,
			...item,
			LeadPurchase: item[`LeadPurchase_${period}`],
			LeadCost: item[`LeadCost_${period}`],
		}))
	const groupedQLM = groupBy(QLM, 'LeadSource')
	Object.keys(groupedQLM).forEach(key => {
		let subSources = [...groupedQLM[key]]
		let summaryRow = {
			LeadSource: key,
			LeadSubSource: 'Total',
			LeadPurchase: calculateSummary(groupedQLM[key], 'LeadPurchase'),
			LeadCost: calculateSummary(groupedQLM[key], 'LeadCost'),
			CloseRatio: calculateSummary(groupedQLM[key], 'CloseRatio'),
			TPCloseRatio: calculateSummary(groupedQLM[key], 'TPCloseRatio'),
		}
		const totalSubSourceIndex = subSources.findIndex(({ LeadSubSource }) => LeadSubSource === 'Total')
		if (totalSubSourceIndex > -1) {
			summaryRow = subSources[totalSubSourceIndex]
			subSources.splice(totalSubSourceIndex, 1)
		}
		groupedQLM[key] = [
			{ LeadSource: key, LeadSubSource: key, },
			...subSources.sort((a, b) => a.LeadSubSource?.localeCompare?.(b.LeadSubSource)),
			summaryRow,
		]
	})
	return Object.values(groupedQLM).flat()
}

const defaultDataConfig = [
	['Mail In', ['MP', 'Spanish MP', 'OA1', 'OA2']],
	['Call In', ['MP']],
	['Lighthouse', ['Gen Life', 'MP', 'IUL']],
	['Economy', ['Gen Life', 'MP']],
	['Analog B', ['B1', 'B2', 'B3', 'B4', 'B5']],
]
const getDefaultData = () => {
	const data = []
	defaultDataConfig.forEach(([LeadSource, LeadSubSources]) => {
		const leads = LeadSubSources.map(LeadSubSource => ({
			LeadSource,
			LeadSubSource,
			LeadPurchase: null,
			LeadCost: null,
			CloseRatio: null,
			TPCloseRatio: null,
		}))
		data.push(...leads)
	})
	return data
}

export default {
    components: {
		DashCard,
		LeadMarketplaceIcon,
		CalculationDot,
	},
	props: {
		items: {
			type: Object,
			default: null,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		infoData: Object,
	},
    computed: {
		headers () {
			return [{
                text: 'Lead Source',
                value: 'LeadSubSource',
            }, {
                text: 'Lead Purchase',
                value: 'LeadPurchase',
            }, {
                text: 'Lead Cost',
                value: 'LeadCost',
            }, {
                text: 'Close Ratio',
                value: 'CloseRatio',
            }, {
                text: 'TP\u00A0Close Ratio',
                value: 'TPCloseRatio',
            }]
		},
		loading () {
			if (!this.items) { return false }
			return this.items.loading
		},
		hasError () {
			if (!this.items) { return false }
			return this.items.error
		},
		noLoadedData () {
			if (this.items && this.items.loaded) {
				if (!this.items.data) { return true }
				return this.items.data.every(item => Object.values(item).every(this.checkNullish))
			}
			return false
		},
		computedData () {
			let data = getDefaultData()
			if (this.items && this.items.loaded && !this.noLoadedData) {
				data = this.items.data
			}
			const monthly = getQLM('Monthly', data)
			const weekly = getQLM('Weekly', data)
			return this.monthlyPeriod ? monthly : weekly
		},
    },
	methods: {
		checkNullish,
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(Math.abs(value), '0,0.00')
		},
		formatItem (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return value
		},
		formatPercent (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(100 * value, '0.0')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	--td-padding: 0 0.75rem;

	color: $color-text;

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep .v-data-table__wrapper {
			max-height: 746px;
			overflow: auto;
			padding-right: 5px;

			&::-webkit-scrollbar {
				width: $scrollbar-width-chrome;
				height: $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 16px $color-dark;
				border-radius: 2 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-water-green;
				border-radius: 0.5 * $scrollbar-width-chrome;
			}

			&::-webkit-scrollbar-corner {
				background: transparent;
			}
		}

		&::v-deep tbody tr:hover {
			background: none !important;
		}

		&::v-deep .v-data-table-header {
			position: sticky;
			top: 0;
			background-color: var(--card-bg);
			z-index: 1;

			th {
				position: sticky;
				top: 0;
				background-color: var(--card-bg);
				z-index: 1;
			}
		}

		&::v-deep .v-data-table-header tr th {
			border-bottom: $border-bottom-th;
			padding: var(--td-padding);
			height: 42px !important;
			line-height: 1.3;
			span {
				display: inline-block;
				color: $color-text;
				font-weight: bold;
				max-width: min-content;
			}
		}

		&::v-deep tbody tr {
			td {
				height: 32px !important;
				border-bottom: $border-bottom-td;
				padding: var(--td-padding);
				line-height: 1.3;
				white-space: nowrap;
			}
			&.summary-row {
				color: $color-water-green;
				font-weight: bold;
				td {
					border-bottom: none !important;
				}
			}
		}
	}

	.source-title {
		color: $color-water-green;
		font-weight: bold;
	}

	.sub-source-title {
		font-weight: bold;
		background-color: $color-water-green-20;
	}
}
</style>
