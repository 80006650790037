<template>
	<div v-show="isActive" class="advance-phase" :class="computedClass">
		<div class="d-flex">
			<v-btn
				class="perform-dash-btn__download-btn ml-auto"
				color="white" text small dark
				:disabled="!isAllowEditing"
				@click="doDialog = true"
			>
				<v-icon color="#46C3B2">icon-download-q</v-icon>
				{{ downloadBtnLabel }}
			</v-btn>
		</div>

		<div :key="agentCode" class="advance-phase-cards">
			<ROADDashboardCard
				:data="cardsData.trends"
				:dropdown-data="cardsData.trendsDropdown"
				:agent="currentAgent"
				:info-data="infoData"
				@reload="reloadData"
			/>

			<div class="advance-phase__board-row">
				<div class="advance-phase__board-col col-leadsUnderManagement">
					<LeadsUnderManagementCard
						:data="cardsData.leadsUnderManagement"
						:monthly-period="monthlyPeriod"
						:info-data="infoData"
						@reload="reloadData"
					/>
				</div>
				<div class="advance-phase__board-col col-roadMetric">
					<ROADMetricCard
						:data="cardsData.roadMetric"
						:monthly-period="monthlyPeriod"
						:info-data="infoData"
						@reload="reloadData"
					/>
				</div>
				<div class="advance-phase__board-col col-developmentPipeline">
					<DevelopmentPipelineCard
						:is-active="isActive"
						:monthly-period="monthlyPeriod"
						:agent="currentAgent"
						:info-data="infoData"
						@loading="dashDataLoading.pipeline = $event"
					/>
				</div>
			</div>
		</div>

		<div class="advance-phase-cards advance-phase-cards--dashboard">
			<AgencyDashboardCard
				:is-active="isActive"
				:monthly-period="monthlyPeriod"
				:agent="currentAgent"
				:info-data="infoData"
				class="full-width"
				@loading="dashDataLoading.agency = $event"
			/>
		</div>

		<DownloadDataDialog
			v-if="doDialog"
			v-model="doDialog"
			:config="downloadDataConfig"
			:agent="agentCode"
			api-path="advance_download_data"
			phase="Advance"
		/>
	</div>
</template>

<script>
import ROADDashboardCard from './DashCards/AdvancePhase/ROADDashboardCard.vue'
import LeadsUnderManagementCard from './DashCards/AdvancePhase/LeadsUnderManagementCard.vue'
import ROADMetricCard from './DashCards/AdvancePhase/ROADMetricCard.vue'
import DevelopmentPipelineCard from './DashCards/AdvancePhase/DevelopmentPipelineCard.vue'
import AgencyDashboardCard from './DashCards/AdvancePhase/AgencyDashboardCard.vue'
import DownloadDataDialog from './Components/DownloadDataDialog.vue'

import PerformDashMixin from './mixins/perform-dash-mixin'
import { metrics } from './config/trends'

const modules = [
	`advance_trends/${metrics.Production}`,
	`advance_trends/${metrics.Recruiting}`,
	`advance_trends/${metrics.Onboarding}`,
	`advance_trends/${metrics.Activation}`,
	`advance_trends/${metrics.Development}`,
	`advance_trends_dropdown/${metrics.Recruiting}`,
	`advance_trends_dropdown/${metrics.Onboarding}`,
	`advance_trends_dropdown/${metrics.Activation}`,
	`advance_trends_dropdown/${metrics.Development}`,
	'advance_leads_under_management',
	'advance_road_metric',
]
const modulesNeedsDatePeriod = [
	`advance_trends_dropdown/${metrics.Recruiting}`,
	`advance_trends_dropdown/${metrics.Onboarding}`,
	`advance_trends_dropdown/${metrics.Activation}`,
	`advance_trends_dropdown/${metrics.Development}`,
	'advance_leads_under_management',
	'advance_road_metric',
]

const downloadDataConfig = [
	{ module: `advance_trends_dropdown/${metrics.Recruiting}`, label: 'Recruiting Activity' },
	{ module: `advance_trends_dropdown/${metrics.Onboarding}`, label: 'Onboarding Activity' },
	{ module: `advance_trends_dropdown/${metrics.Activation}`, label: 'Activation Activity' },
	{ module: `advance_trends_dropdown/${metrics.Development}`, label: 'Development Activity' },
	{ module: 'advance_leads_under_management', label: 'Leads Under Management' },
	{ module: 'advance_road_metric', label: 'The R.O.A.D. Metric' },
]

export default {
    components: {
		ROADDashboardCard,
		LeadsUnderManagementCard,
		ROADMetricCard,
		DevelopmentPipelineCard,
		AgencyDashboardCard,
		DownloadDataDialog,
	},
	mixins: [PerformDashMixin],
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		agent: {
			type: Object,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
		infoData: Object,
	},
    data () {
        return {
			modules,
			doDialog: false,
			dashDataLoading: {
				pipeline: false,
				agency: false,
			},
			downloadDataConfig,
		}
    },
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedClass () {
			return [
				this.isMobileView && 'advance-phase--mobile',
				this.cardMobileOrder && 'advance-phase--mobile-order',
			]
		},
		downloadBtnLabel () {
			return this.isMobileView
				? 'Download Advance Data'
				: 'Download PerformDash Advance Data'
		},
		currentAgent () {
			return this.agent || this.user.Agent
		},
		agentCode () {
			return this.currentAgent.AgentCode
		},
		modulesParams () {
			return {
				[`advance_trends/${metrics.Production}`]: { cancelRequestModule: [`advance_trends/${metrics.Production}`] },
				[`advance_trends/${metrics.Recruiting}`]: { cancelRequestModule: [`advance_trends/${metrics.Recruiting}`] },
				[`advance_trends/${metrics.Onboarding}`]: { cancelRequestModule: [`advance_trends/${metrics.Onboarding}`] },
				[`advance_trends/${metrics.Activation}`]: { cancelRequestModule: [`advance_trends/${metrics.Activation}`] },
				[`advance_trends/${metrics.Development}`]: { cancelRequestModule: [`advance_trends/${metrics.Development}`] },
				[`advance_trends_dropdown/${metrics.Recruiting}`]: { cancelRequestModule: [`advance_trends_dropdown/${metrics.Recruiting}`] },
				[`advance_trends_dropdown/${metrics.Onboarding}`]: { cancelRequestModule: [`advance_trends_dropdown/${metrics.Onboarding}`] },
				[`advance_trends_dropdown/${metrics.Activation}`]: { cancelRequestModule: [`advance_trends_dropdown/${metrics.Activation}`] },
				[`advance_trends_dropdown/${metrics.Development}`]: { cancelRequestModule: [`advance_trends_dropdown/${metrics.Development}`] },
			}
		},
		cardsData () {
			return {
				trends: {
					[metrics.Production]: this.modulesData[`advance_trends/${metrics.Production}`],
					[metrics.Recruiting]: this.modulesData[`advance_trends/${metrics.Recruiting}`],
					[metrics.Onboarding]: this.modulesData[`advance_trends/${metrics.Onboarding}`],
					[metrics.Activation]: this.modulesData[`advance_trends/${metrics.Activation}`],
					[metrics.Development]: this.modulesData[`advance_trends/${metrics.Development}`],
				},
				trendsDropdown: {
					[metrics.Recruiting]: this.modulesData[`advance_trends_dropdown/${metrics.Recruiting}/${this.datePeriod}`],
					[metrics.Onboarding]: this.modulesData[`advance_trends_dropdown/${metrics.Onboarding}/${this.datePeriod}`],
					[metrics.Activation]: this.modulesData[`advance_trends_dropdown/${metrics.Activation}/${this.datePeriod}`],
					[metrics.Development]: this.modulesData[`advance_trends_dropdown/${metrics.Development}/${this.datePeriod}`],
				},
				leadsUnderManagement: this.modulesData[`advance_leads_under_management/${this.datePeriod}`],
				roadMetric: this.modulesData[`advance_road_metric/${this.datePeriod}`],
			}
		},
		isAllowEditing () {
			return this.agentCode === this.user.AgentCode
		},
		someDataLoading () {
			return [...Object.values(this.dashDataLoading), this.dataLoading].some(Boolean)
		},
    },
	watch: {
		someDataLoading () {
			this.$emit('loading', this.someDataLoading)
		},
		monthlyPeriod () {
			this.loadData(modulesNeedsDatePeriod)
		},
	},
    methods: {
		getEmptyModulesData () {
			return this.modules.reduce((acc, item) => {
				if (modulesNeedsDatePeriod.includes(item)) {
					acc[`${item}/Monthly`] = undefined
					acc[`${item}/Weekly`] = undefined
				} else {
					acc[item] = undefined
				}
				return acc
			}, {})
		},
		addPathDetails (modules) {
			return modules.map((module) => {
				if (modulesNeedsDatePeriod.includes(module)) {
					return `${module}/${this.datePeriod}`
				}
				return module
			})
		},
		getModuleParams (modulePath) {
			let module = modulePath.split('/')[0]
			if (module === 'advance_trends' || module === 'advance_trends_dropdown') {
				const sub = modulePath.split('/')[1]
				module = `${module}/${sub}`
			}
			return this.modulesParams[module] ?? { itemsPerPage: 'all' }
		},
	},
}
</script>

<style lang="scss" scoped>
@import './style/index.scss';

$block: advance-phase;

.#{$block} {
	--row-gap: 2.5rem;
	--col-gap: 2rem;

	.advance-phase-cards {
		margin-top: 1rem;
		width: 100%;
		color: $color-text;
		display: flex;
		flex-direction: column;
		row-gap: var(--row-gap);
		column-gap: var(--col-gap);
	}

	.advance-phase-cards--dashboard {
		margin-top: var(--row-gap);
	}

	&__board-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: var(--row-gap);
		column-gap: var(--col-gap);
	}

	&__board-col {
		max-width: 100%;
	}

	.col-leadsUnderManagement {
		flex: 1 1 340px;
	}

	.col-roadMetric {
		flex: 1 1 380px;
	}

	.col-developmentPipeline {
		flex: 1 0 47.5rem;
	}
}
</style>
