<template>
	<DashCard
		:title="title"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Advance: Agency Dashboard. Downlines"
		@reload="$emit('reload', 'advance_mad_individual')"
	>
		<div class="card-content">
			<v-data-table
				:headers="headers"
				:items="computedData"
				:item-key="itemKey"
				:page.sync="computedOptions.page"
				:items-per-page.sync="computedOptions.itemsPerPage"
				:sort-by.sync="computedOptions.sortBy"
				:sort-desc.sync="computedOptions.sortDesc"
				:server-items-length="serverItemsLength"
				:footer-props="{
					itemsPerPageOptions: [7, 25, 50, 100],
					itemsPerPageText: isMobileView ? '' : 'Rows per page',
				}"
				:hide-default-footer="noLoadedData || !serverItemsLength"
				fixed-header
				dark
				:mobile-breakpoint="0"
				class="advance__agency-dashboard-table"
			>
				<template v-for="h in headers" v-slot:[`item.${h.value}`]="{ value }">
					<span
						v-if="h.value === 'AgentName'" :key="h.value"
						class="nowrap advance__agency-dashboard-table__item"
					>
						{{ value || 'NA' }}
					</span>
					<span v-else :key="h.value">
						<template v-if="isCurrency(h.value)">
							<span v-if="Number(value) < 0">-</span>
							<span class="with-currency">{{ formatMoney(value) }}</span>
						</template>
						<template v-else>{{ formatItem(value, h.value) }}</template>
					</span>
				</template>
			</v-data-table>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'

import { downlinesConfig as config, getEmptyData } from '../../config/agencyDashboard'

export default {
    components: {
		DashCard,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		options: {
			type: Object,
			required: true,
		},
		serverItemsLength: {
			type: Number,
			default: -1,
		},
		agent: Object,
		infoData: Object,
	},
	data () {
		return {
			itemKey: 'AgentID',
		}
	},
    computed: {
		computedOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every((item) => {
					return config.every(({ key }) => this.checkNullish(item[key]))
				})
			}
			return false
		},
		computedData () {
			let data = getEmptyData(this.itemKey)
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data
			}
			return data
		},
		headers () {
			return config.map((item) => ({
				text: item.label,
                value: item.key,
				sortable: Boolean(item.sortable),
				class: 'nowrap',
			}))
		},
		title () {
			return 'Downlines'
			// return this.agent
			// 	? `Downlines (${this.agent.AgentName})`
			// 	: 'Downlines'
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatItem (value, key) {
			const isNullish = this.checkNullish(value)
			if (isNullish) { return 'NA' }
			if (this.isDefaultType(key)) { return value }
			return this.formatNumber(value, '0,0')
		},
		formatNumberItem (value) {
			const isNullish = this.checkNullish(value)
			if (isNullish) { return 'NA' }
			return this.formatNumber(value, '0,0')
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(Math.abs(value), '0,0[.]00')
		},
		isCurrency (key) {
			return config.find((item) => item.key === key)?.type === 'currency'
		},
		isDefaultType (key) {
			return config.find((item) => item.key === key)?.type === 'default'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.dash-card {
	margin-left: -1rem;
	margin-right: -1rem;
	padding: 0.5rem 1.5rem 1.5rem;

	// &--mobile {
	// 	padding: 0.5rem 1rem 1.5rem;
	// }

	::v-deep .dash-card__content {
		min-height: 9rem;
	}

	::v-deep .dash-card__header {
		min-height: 2rem;
	}
}
</style>
