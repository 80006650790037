import QuilityAPI from '@/store/API/QuilityAPI'

const mixin = {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
			dataCache: {},
			dataLoading: false,
			dataLoadingError: false,

			modules: [],
			modulesData: {},
		}
	},
	created () {
		this.modulesData = this.getEmptyModulesData()
	},
	computed: {
		agentCode () {
			return this.user.AgentCode
		},
		modulesAgentCode () {
			return {}
		},
		modulesParams () {
			return {}
		},
		datePeriod () {
			return this.monthlyPeriod ? 'Monthly' : 'Weekly'
		},
		mode () {
			return { isActive: this.isActive, agentCode: this.agentCode }
		},
	},
	watch: {
		mode: {
			immediate: true,
			handler () {
				this.modeWatcherHandler()
			},
		},
	},
	methods: {
		modeWatcherHandler () {
			if (!this.mode.isActive) { return }
			this.updateData()
		},
		getEmptyModulesData () {
			return this.modules.reduce((acc, item) => {
				acc[item] = undefined
				return acc
			}, {})
		},
		async updateData () {
			if (!this.dataCache[this.agentCode]) {
				this.dataCache[this.agentCode] = {}
			}
			this.loadData(this.modules)
		},
		async loadData (items, forceReload) {
			const modules = this.addPathDetails(items)
			this.resetModulesState(modules)
			const uncachedModules = []
			if (forceReload) {
				uncachedModules.push(...modules)
			} else {
				// check cache
				modules.forEach(module => {
					const agentCode = this.getModuleAgentCode(module)
					if (this.dataCache[agentCode][module] && !this.dataCache[agentCode][module].error) {
						this.modulesData[module] = { ...this.dataCache[agentCode][module] }
					} else {
						uncachedModules.push(module)
					}
				})
			}
			if (!uncachedModules.length) {
				return Promise.resolve()
			}
			// call API
			this.setModulesLoading(uncachedModules)
			this.dataLoading = true
			this.dataLoadingError = false
			const promises = uncachedModules.map((module) => {
				const agentCode = this.getModuleAgentCode(module)
				const params = this.getModuleParams(module)
				return QuilityAPI.loadPerformDashModule(module, agentCode, params)
			})
			const responses = await Promise.all(promises)
			responses.forEach((res, i) => {
				if (res.error && res.text === 'Aborted') {
					// console.error('Aborted')
				} else {
					const state = { loaded: true }
					if (res.error) {
						state.data = null
						state.loading = false
						state.error = true
					} else {
						state.data = this.processData(res)
						state.loading = false
						state.error = false
					}
					this.processModule(uncachedModules[i], res)
					const agentCode = this.getModuleAgentCode(uncachedModules[i])
					this.dataCache[agentCode][uncachedModules[i]] = state
					this.modulesData[uncachedModules[i]] = { ...state }
				}
			})
			this.dataLoadingError = Object.values(this.modulesData).filter(Boolean).every(({ error }) => error)
			this.dataLoading = false
		},
		processData (res) {
			if (res.data) {
				if (this.checkEmptyData(res.data)) { return null }
				return res.data
			}
			if (this.checkEmptyData(res)) { return null }
			return res
		},
		checkEmptyData (data) {
			if (!data) { return true }
			if (Array.isArray(data) && !data.length) { return true }
			if (typeof data === 'object' && !Object.keys(data).length) { return true }
			return false
		},
		resetModulesState (modules) {
			modules.forEach(module => {
				if (!this.modulesData[module]) {
					this.modulesData[module] = {}
				}
				this.modulesData[module].data = null
				this.modulesData[module].loading = false
				this.modulesData[module].error = false
				this.modulesData[module].loaded = false
			})
		},
		setModulesLoading (modules) {
			modules.forEach(module => {
				this.modulesData[module].loading = true
			})
		},
		reloadData (module) {
			const modules = Array.isArray(module) ? module : [module]
			this.loadData(modules)
		},
		addPathDetails (modules) {
			return modules
		},
		getModuleAgentCode (modulePath) {
			const module = modulePath.split('/')[0]
			return this.modulesAgentCode[module] ?? this.agentCode
		},
		getModuleParams (modulePath) {
			const module = modulePath.split('/')[0]
			return this.modulesParams[module] ?? { itemsPerPage: 'all' }
		},
		processModule (modulePath, response) {},
	}
}

export default mixin
