<template>
	<DashCard
		title="R.O.A.D. Dashboard"
		:info-data="infoData"
		info-data-title="Advance: R.O.A.D. Dashboard"
		without-divider
		centered-title
	>
		<template #title-container>
			<div class="dash-card__title-container">
				<div v-if="!isMobileView && !isTabletView" class="advance-phase__trends-legend advance-phase__trends-legend--grow" />
				<div class="dash-card__title-wrapper justify-center">
					<div class="mr-4 d-flex align-center"><Icon /></div>
					<div class="dash-card__title advance-phase__main-card-title">R.O.A.D. Dashboard</div>
				</div>
				<div class="advance-phase__trends-legend" :class="!isMobileView && !isTabletView && 'advance-phase__trends-legend--grow'">
					<div v-for="item in chartsLegend" :key="item.level" class="trends-legend__item">
						<div class="trends-legend__marker" :style="`background-color: ${item.color};`" />
						<div class="trends-legend__label">{{ item.label }}</div>
					</div>
				</div>
			</div>
		</template>

		<div class="trends-charts-row">
			<div v-for="data in computedData" :key="data.metric" class="trends-charts-col">
				<TrendsChartCard
					:metric="data.metric"
					:data="data.data"
					:agency-levels="availableAgencyLevels"
					:title="data.title"
					:chart-label="data.chartLabel"
					:info-data="infoData"
					@reload="$emit('reload', $event)"
				/>

				<TrendsDropdownModule
					v-if="data.metric !== metrics.Production"
					:data="data.dropdownData"
					:metric="data.metric"
					:label="data.dropdownLabel"
					@reload="$emit('reload', `advance_trends_dropdown/${data.metric}`)"
				/>
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/LeadsOverviewIcon.vue'
import TrendsChartCard from './TrendsChartCard.vue'
import TrendsDropdownModule from './TrendsDropdownModule.vue'

import { agencyLevels, agencyLevelsConfig } from '../../config/common'
import LeadershipLevelsMixin from '../../mixins/leadership-levels-mixin'
import { metrics, chartsConfig, chartsLevels, chartsLevelLabels, GoalAPV, colors } from '../../config/trends'

export default {
    components: {
		DashCard,
		Icon,
		TrendsChartCard,
		TrendsDropdownModule,
	},
	mixins: [LeadershipLevelsMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		dropdownData: {
			type: Object,
			default: null,
		},
		agent: Object,
		infoData: Object,
	},
	data () {
		return {
			metrics,
		}
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		computedData () {
			return chartsConfig.map((conf) => ({
				...conf,
				data: this.data?.[conf.metric] || {},
				dropdownData: this.dropdownData?.[conf.metric] || {},
			}))
		},
		chartsLegend () {
			return chartsLevels
				.filter((level) => {
					if (level === GoalAPV) { return true }
					return this.availableAgencyLevels.includes(level)
				})
				.map((level) => ({
					level,
					label: chartsLevelLabels[level],
					color: colors[level],
				}))
		},
		currentAgent () {
			return this.agent || this.user.Agent
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.sortedLeadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.sortedLeadershipLevels.indexOf(this.currentAgent.LeadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		currentAgentContractLevel () {
			return Number(this.currentAgent.ContractLevel)
		},
		agencyOptionsCase1 () {
			return this.leadershipOrderRelativeToAO < 0
		},
		agencyOptionsCase2 () {
			return this.leadershipOrderRelativeToAO === 0
		},
		agencyOptionsCase3 () {
			return this.leadershipOrderRelativeToAO > 0 && this.currentAgentContractLevel < 130
		},
		agencyOptionsCase4 () {
			return this.leadershipOrderRelativeToAO > 0 && this.currentAgentContractLevel >= 130
		},
		allowBaseOption () {
			return this.agencyOptionsCase2 || this.agencyOptionsCase3 || this.agencyOptionsCase4
		},
		allowDirectOption () {
			return this.agencyOptionsCase4
		},
		allowTotalOption () {
			return this.agencyOptionsCase1 || this.agencyOptionsCase3 || this.agencyOptionsCase4
		},
		agencyLevelOptions () {
			return agencyLevelsConfig.map((item) => {
				let disabled = false
				switch (item.value) {
					case agencyLevels.Base:
						disabled = !this.allowBaseOption
						break
					case agencyLevels.Direct:
						disabled = !this.allowDirectOption
						break
					case agencyLevels.Total:
						disabled = !this.allowTotalOption
						break
					default: disabled = false
				}
				return { ...item, disabled }
			})
		},
		availableAgencyLevels () {
			return this.agencyLevelOptions
				.filter((option) => !option.disabled)
				.map((option) => option.value)
		},
    },
}
</script>

<style lang="scss" scoped>
.advance-phase__trends-legend {
	display: flex;
	flex-wrap: wrap;
	justify-items: flex-end;
	gap: 0.5rem 1rem;
}

.advance-phase__trends-legend--grow {
	flex: 1 1 10rem;
	max-width: 24rem;
}

.trends-legend__item {
	display: flex;
	align-items: center;
	column-gap: 0.5rem;

	.trends-legend__marker {
		width: 1rem;
		height: 1rem;
		border-radius: 1px;
	}
	
	.trends-legend__label {
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1;
		text-wrap: nowrap;
	}
}

.trends-charts-row {
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 2.5rem 2.5rem;
}

.trends-charts-col {
	max-width: 30rem;
	min-width: 15rem;
	flex: 1 1 15rem;
}
</style>
