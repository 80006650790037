<template>
	<InfoDialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:mobile-fullscreen="false"
		content-class="perform-dash__external-lead-sources-dialog"
	>
		<template #title>
			<div class="dialog-title-wrapper">
				<h3 class="dialog-title">{{ title }}</h3>
				<p v-if="computedDate" class="dialog-title-date">{{ computedDate }}</p>
			</div>
		</template>

		<div>Period: <span class="dialog-period">{{ monthlyPeriod ? 'Monthly' : 'Weekly' }}</span></div>

		<v-form ref="form" v-model="validForm" :disabled="saving" class="mt-6">
			<v-row dense>
				<v-col cols="12">
					<label for="field-LeadSource" class="form-label">Lead Source</label>
					<v-text-field
						id="field-LeadSource"
						v-model.trim="form.LeadSource"
						:rules="[form_rules.required]"
						required outlined dense color="#46C3B2"
						placeholder="Add lead source"
						class="mt-1 pt-0"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" sm="4">
					<label for="field-LeadPurchase" class="form-label"># of Leads Purchased</label>
					<v-text-field
						id="field-LeadPurchase"
						v-model.trim.number="form.LeadPurchase"
						:rules="[form_rules.required, form_rules.number, form_rules.int]"
						required outlined dense color="#46C3B2"
						placeholder="Add lead purchase"
						class="mt-1 pt-0"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<label for="field-LeadCost" class="form-label">Lead Cost</label>
					<v-text-field
						id="field-LeadCost"
						v-model.trim.number="form.LeadCost"
						:rules="[form_rules.required, form_rules.number]"
						required outlined dense color="#46C3B2"
						placeholder="Add lead cost"
						prepend-inner-icon="fas fa-dollar-sign"
						class="mt-1 pt-0 form-field"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<label for="field-CloseRatio" class="form-label">Close Ratio</label>
					<v-text-field
						id="field-CloseRatio"
						v-model.trim.number="form.CloseRatio"
						:rules="[form_rules.required, form_rules.number, nonZeroRule]"
						required outlined dense color="#46C3B2"
						placeholder="Add close ratio"
						append-icon="fas fa-percent"
						class="mt-1 pt-0"
					/>
				</v-col>
			</v-row>

			<v-row dense class="mt-4">
				<v-alert v-if="warningMessage" dense type="warning" class="mb-8 full-width">{{ warningMessage }}</v-alert>

				<v-col class="d-flex">
					<v-btn class="perform-dash-btn ml-auto" color="#000F2C" outlined @click="computedValue = false">
						Cancel
					</v-btn>
					<v-btn class="perform-dash-btn ml-4" color="#46C3B2" :disabled="!submitEnabled" :dark="submitEnabled" @click="save">
						<v-progress-circular v-if="saving" class="mr-2" left size="20" width="2" indeterminate />
						Save
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</InfoDialog>
</template>

<script>
import moment from 'moment'

import QuilityAPI from '@/store/API/QuilityAPI'
import InfoDialog from './InfoDialog.vue'

const serverDateFormat = 'YYYY-MM-DD'
const dateFormat = 'MMMM DD, YYYY'

const emptyForm = {
	LeadSource: '',
	LeadPurchase: undefined,
	LeadCost: undefined,
	CloseRatio: undefined,
}

export default {
	components: { InfoDialog },
	props: {
		value: { type: Boolean, default: false },
		item: { type: Object },
		mode: { type: String, required: true },
		monthlyPeriod: { type: Boolean, default: true },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '660px' },
	},
	data () {
		return {
			form: {},
			validForm: false,
			saving: false,
			warningMessage: '',
		}
	},
	created () {
		this.form = this.mode === 'edit'
			? { ...this.item }
			: { ...emptyForm }
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		agentCode () {
			return this.user.AgentCode
		},
		title () {
			return this.mode === 'new' ? 'New Lead Source' : 'Edit Lead Source'
		},
		computedDate () {
			if (!this.item) { return undefined }
			return moment(this.item.LastChangeDate, serverDateFormat).format(dateFormat)
		},
		formChanges () {
			const base = this.mode === 'edit' ? (this.item || {}) : emptyForm
			return Object.keys(emptyForm)
				.map(key => {
					const before = base[key]
					const after = this.form[key]
					if (before === after) { return false }
					return { key, before, after }
				})
				.filter(Boolean)
		},
		submitEnabled () {
			return Boolean(this.formChanges.length && !this.saving)
		},
	},
	methods: {
		validateForm () {
			if (!this.$refs['form'].validate()) {
                this.warningMessage = 'Please correct invalid fields.'
                setTimeout(() => { this.warningMessage = '' }, 5000)
                return false
            }
			return true
		},
		async save () {
			if (!this.validateForm()) { return }
			if (this.saving) { return }
            this.saving = true
			const form = this.getFormFields()
            const res = this.mode === 'new'
				? (await QuilityAPI.createMonthlyExternalLeadSource(this.agentCode, form))
				: (await QuilityAPI.updateMonthlyExternalLeadSource(form))
            if (res.error) {
                this.showError(`Oops! There was a problem submitting External Lead Source.<br>`, res.message)
                console.error(res.error)
            } else {
                this.$emit('save')
                this.computedValue = false
            }
            this.saving = false
		},
		getFormFields () {
			let { LeadCost, LeadPurchase, ...fields } = this.form
			LeadCost = Number(LeadCost)
			LeadPurchase = Number(LeadPurchase)
			// new LeadSource or weekly mode
			if (this.mode === 'new' || !this.monthlyPeriod) {
				return {
					...fields,
					LeadCost_Weekly: LeadCost,
					LeadPurchase_Weekly: LeadPurchase,
				}
			}
			// monthly mode
			const isNewWeek = moment().isAfter(this.item.BusinessWeekEnd, serverDateFormat)
			const LeadCost_Weekly = isNewWeek
				? LeadCost - this.item.LeadCost_Monthly
				: LeadCost - (this.item.LeadCost_Monthly - this.item.LeadCost_Weekly)
			const LeadPurchase_Weekly = isNewWeek
				? LeadPurchase - this.item.LeadPurchase_Monthly
				: LeadPurchase - (this.item.LeadPurchase_Monthly - this.item.LeadPurchase_Weekly)
			return {
				...fields,
				LeadCost_Weekly,
				LeadPurchase_Weekly,
			}
		},
		nonZeroRule (v) {
			if (!v) { return true }
			const rounded = Math.round(Number(v) * 100) / 100
			if (rounded !== 0) { return true }
			return 'Enter a non-zero number'
		},
	},
}
</script>

<style lang="scss">
@import '../style/index.scss';

.perform-dash__external-lead-sources-dialog {
	--padding-x: 3rem;

	&:not(.v-dialog--fullscreen) {
		.info-card {
			border-radius: $card-border-radius;
		}
	}

	.v-card__title {
		padding: 2.5rem var(--padding-x) 0 !important;
	}

	.v-card__text {
		padding: 0.5rem var(--padding-x) 2rem !important;
	}

	.info-card-title-btn {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.perform-dash__external-lead-sources-dialog.perform-dash__info-dialog--mobile {
	--padding-x: 1.5rem;
}
</style>


<style lang="scss" scoped>
@import '../style/index.scss';

.perform-dash__external-lead-sources-dialog {
	.dialog-title-wrapper {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 0.25rem 2rem;
		padding-bottom: 0.5rem;
		border-bottom: 2px solid $color-border-dark;
	}

	.dialog-title {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 1.125rem;
		color: $color-water-green;
		letter-spacing: normal;
	}

	.dialog-title-date {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 0.875rem;
		color: $color-dark;
	}

	.dialog-period {
		color: $color-water-green;
		line-height: 1.2;
		font-weight: 600;
	}

	.form-label {
		line-height: 1.2;
		font-size: 0.875rem;
		font-weight: 600;
		color: $color-dark;
	}

	.form-field {
		::v-deep .v-input__slot {
			padding-left: 0.25rem !important;
		}
	}
}
</style>
