<template>
	<DashCard
		title="Development Pipeline"
		without-divider
		without-info
		centered-title
		full-height
	>
		<template #icon><Icon /></template>

		<template #title>
			<div class="dash-card__title advance-phase__main-card-title">Development Pipeline</div>
		</template>

		<div class="DP-card__row" :class="computedClass">
			<div class="DP-card__col">
				<ContractLevelsCard
					:data="cardsData.contractLevels"
					:info-data="infoData"
					@reload="reloadData"
				>
					<template #agency-level>
						<AgencyLevelSelector
							v-model="agencyLevel"
							:options="agencyLevelOptions"
						/>
					</template>
				</ContractLevelsCard>
			</div>

			<div class="DP-card__col">
				<DistributionByLOLCard
					:data="cardsData.devPipe"
					:info-data="infoData"
					@reload="reloadData"
				/>
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/DevelopmentPipelineIcon.vue'
import ContractLevelsCard from './ContractLevelsCard.vue'
import DistributionByLOLCard from './DistributionByLOLCard.vue'
import AgencyLevelSelector from '../../Components/AgencyLevelSelector'

import LeadershipLevelsMixin from '../../mixins/leadership-levels-mixin'
import PerformDashMixin from '../../mixins/perform-dash-mixin'
import { agencyLevels, agencyLevelsConfig } from '../../config/common'

const modules = [
	'advance_contract_levels',
	'advance_devpipe',
]
const modulesNeedsDatePeriod = [
	'advance_devpipe',
]
const modulesNeedsAgencyLevel = [
	'advance_contract_levels',
]

export default {
    components: {
		DashCard,
		Icon,
		ContractLevelsCard,
		DistributionByLOLCard,
		AgencyLevelSelector,
	},
	mixins: [PerformDashMixin, LeadershipLevelsMixin],
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		agent: Object,
		infoData: Object,
	},
	data () {
        return {
			modules,
			agencyLevel: agencyLevels.Base,
		}
    },
	computed: {
		isCardMobileOrder () {
			return this.$vuetify.breakpoint.mdAndDown
		},
		computedClass () {
			return [
				this.isCardMobileOrder && 'DP-card__row--mobile-order',
			]
		},
		currentAgent () {
			return this.agent || this.user.Agent
		},
		agentCode () {
			return this.currentAgent.AgentCode
		},
		cardsData () {
			return {
				contractLevels: this.modulesData[`advance_contract_levels/${this.agencyLevel}`],
				devPipe: this.modulesData[`advance_devpipe/${this.datePeriod}`],
			}
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.sortedLeadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.sortedLeadershipLevels.indexOf(this.currentAgent.LeadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		currentAgentContractLevel () {
			return Number(this.currentAgent.ContractLevel)
		},
		agencyOptionsStaffCase () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
		agencyOptionsCase1 () {
			return this.leadershipOrderRelativeToAO < 0
		},
		agencyOptionsCase2 () {
			return this.leadershipOrderRelativeToAO === 0
		},
		agencyOptionsCase3 () {
			return this.leadershipOrderRelativeToAO > 0 && this.currentAgentContractLevel < 130
		},
		agencyOptionsCase4 () {
			return this.leadershipOrderRelativeToAO > 0 && this.currentAgentContractLevel >= 130
		},
		allowBaseOption () {
			return this.agencyOptionsStaffCase || this.agencyOptionsCase2 || this.agencyOptionsCase3 || this.agencyOptionsCase4
		},
		allowDirectOption () {
			return this.agencyOptionsStaffCase || this.agencyOptionsCase4
		},
		allowTotalOption () {
			return this.agencyOptionsStaffCase || this.agencyOptionsCase1 || this.agencyOptionsCase3 || this.agencyOptionsCase4
		},
		agencyLevelOptions () {
			return agencyLevelsConfig.map((item) => {
				let disabled = false
				switch (item.value) {
					case agencyLevels.Base:
						disabled = !this.allowBaseOption
						break
					case agencyLevels.Direct:
						disabled = !this.allowDirectOption
						break
					case agencyLevels.Total:
						disabled = !this.allowTotalOption
						break
					default: disabled = false
				}
				return { ...item, disabled }
			})
		},
	},
	watch: {
		dataLoading () {
			this.$emit('loading', this.dataLoading)
		},
		monthlyPeriod () {
			this.loadData(modulesNeedsDatePeriod)
		},
		agencyLevel () {
			this.loadData(modulesNeedsAgencyLevel)
		},
	},
	methods: {
		modeWatcherHandler () {
			if (!this.mode.isActive) { return }
			this.agencyLevel = (!this.agencyOptionsStaffCase && this.agencyOptionsCase1) ? agencyLevels.Total : agencyLevels.Base
			this.updateData()
		},
		getEmptyModulesData () {
			return this.modules.reduce((acc, item) => {
				if (modulesNeedsDatePeriod.includes(item)) {
					acc[`${item}/Monthly`] = undefined
					acc[`${item}/Weekly`] = undefined
				} else if (modulesNeedsAgencyLevel.includes(item)) {
					acc[`${item}/${agencyLevels.Base}`] = undefined
					acc[`${item}/${agencyLevels.Direct}`] = undefined
					acc[`${item}/${agencyLevels.Total}`] = undefined
				} else {
					acc[item] = undefined
				}
				return acc
			}, {})
		},
		addPathDetails (modules) {
			return modules.map((module) => {
				if (modulesNeedsDatePeriod.includes(module)) {
					return `${module}/${this.datePeriod}`
				}
				if (modulesNeedsAgencyLevel.includes(module)) {
					return `${module}/${this.agencyLevel}`
				}
				return module
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.DP-card__row {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 2.5rem 3.5rem;
}

.DP-card__row--mobile-order {
	flex-wrap: wrap;
}

.DP-card__col {
	max-width: 100%;
	flex: 1 0 20rem;
}
</style>
